import React from 'react';
import styled, { useTheme } from 'styled-components';

import { demoUrl } from '../constants';
import Body01 from '../components/typography/Body01';
import Body02 from '../components/typography/Body02';
import Button from '../components/Button';
import Headline03 from '../components/typography/Headline03';
import ListItem from '../components/ListItem';
import img from '../img/3.jpeg';

const items = [
    "Doesn't tear or deteriorate",
    "Safe and hygienic",
    "Environmentally friendly",
]
const Features = () => {
    const theme = useTheme();

    return (
        <Root>  
            <Block>
                <Grid4>
                    <Col>
                        <Body01 bold>
                            Easy to use
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            All you need is your mobile phone to fully control and edit your menu anytime anywhere. 
                            It takes few minutes to set up, just register and start creating items. 
                        </Body02>
                    </Col>
                    <Col>
                        <Body01 bold>
                            Realtime updates
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            Check your stock & edit your menu on the go. Whether you change price or hide item, that is out of stock, guests see changes on their devices immediately, without having to refresh the page
                        </Body02>
                    </Col>

                    <Col>
                        <Body01 bold>
                            Multilingual support
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            Communicate more effectively with your customers. Your menu is automatically translated to English, Greek and Russian languages. Need another language? Contact us and we'll add it
                        </Body02>
                    </Col>

                    <Col>
                        <Body01 bold>
                            Promo campaigns
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            Highlight a specific menu item, offer special deals or 
                            tell about upcoming events and activities
                        </Body02>
                    </Col>
                </Grid4>
            </Block>
            <NextBlock>
                <Headline03>
                    Better than a <span1>printed</span1> menu
                </Headline03>
                
                <StyledGrid2>
                    <Img><Grad /><img src={ img } alt="reaction.menu" /></Img>

                    <RightSide>
                        <Body01 bold>
                            No need to reprint menus
                            to change prices or update item descriptions — 
                            just open <span>reaction.menu</span> and edit in 2 clicks
                        </Body01>

                        <Items>
                            { items.map(item => (
                                 <ListItem item={ item } />
                            ))}
                        </Items>

                        <StyledButton label="Try admin demo"
                        to={ demoUrl }
                        blank
                        background={ theme.primary }
                        />
                    </RightSide>
                </StyledGrid2>
            </NextBlock>
        </Root>
    )
};

export default Features;

const Root = styled.div`    
width: 100%;
padding: 0 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
    }
`;

const Grad = styled.div`
    background: radial-gradient(rgba(0, 0, 0, 0), ${ ({ theme }) => theme.body } );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

const Block = styled.div`
margin: 32px 0 40px;
`;

const NextBlock = styled.div`
    margin: 80px 0;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        margin: 80px 0 120px;
    }
`;

const StyledButton = styled(Button)`
`;

const RightSide = styled.div`
    
`;


const Items = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin: 32px 0 40px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        gap: 16px;
    }
`;

const Img = styled.div`
    position: relative;
    content: "";
    display: block;
    padding-bottom: 100%;

    img{
        background: ${ ({ theme }) => theme.border };
        border-radius: 16px;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        height: 400px;
        padding-bottom: 0;
    }
`;

const Grid2 = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 0px;

    };
`;

const Grid4 = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    };
`;

const Col = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        gap: 8px;
    };
`;

const StyledGrid2 = styled(Grid2)`
    margin-top: 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        align-items: center;
    };
`;