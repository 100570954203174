import styled from 'styled-components';

const Headline03 = styled.h3`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 8vw;
    font-family: extraBold, Arial;
    letter-spacing: -1px;
    line-height: 120%;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };

    span2 {
        color: ${ ({ theme }) => theme.primary };
    }

    span1 {
        -webkit-text-fill-color: ${ ({ theme }) => theme.body };
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: ${ ({ theme }) => theme.text };
    }
    
    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) { 
        font-size: 3vw;
    }
`;

export default Headline03;
