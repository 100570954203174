import styled from 'styled-components';

const Body02 = styled.p`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 18px;
    font-family: ${ ({ bold }) =>
    bold ? 'bold, Arial'
    : 'regular, Arial' };
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };
    line-height: 22px;

    span {
        color: ${ ({ theme }) => theme.text };
    }
`;

export default Body02;