import styled, { useTheme } from 'styled-components';
import React from 'react';

import Button from '../components/Button';
import { demoUrl, signupUrl } from '../constants';

const Buttons = ({ className }) => {
    const theme = useTheme();

    return (
        <Roots className={ className }>
            <Button label="Get started"
                arrow
                to={ signupUrl }
                blank
                background={ theme.primary }
            />

            <Button label="Try demo"
                to={ demoUrl }
                blank
                background='transparent'
                color={ theme.text }
                border={ theme.border }
            />
        </Roots>
    );}

export default Buttons;

const Roots = styled.div`
    display: none;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        flex-direction: row;
        gap: 32px;
        display: flex;
    }
`;