import styled from 'styled-components';
import React from 'react';

import Button from '../components/Button';
import { signupUrl } from '../constants';
import Shape from '../components/Shape';

const Signup = () => {
    return (
        <Root>
            <Button label="Sign up" 
                to={ signupUrl }
                blank
                    />
            <StyledShape />
        </Root>
    );
};

export default Signup;

const Root = styled.div`
    position: fixed;
    right: 0;
    top: 4px;
    z-index: 100;
`;

const StyledShape = styled(Shape)`
position: absolute;
right: -20px;
top: -60px;
width: 140px;
height: 140px;

@media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
    display: none;
};
`;



