import styled from 'styled-components';
import React from 'react';

import { ReactComponent as Logo } from '../img/logoGreek.svg';


const Header = () => {
    return (
            <Root>
                <StyledLogo />
            </Root>
    );
};

export default Header;

const Root = styled.div`
    align-items: center;
    height: 80px; 
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    z-index: 100;
    position: fixed;
    background: ${ ({ theme }) => theme.body };
        background: linear-gradient(180deg, ${ ({ theme }) => theme.body } 20%, rgba(255,255,255,0) 100%); 

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
           
    }
`;

const StyledLogo = styled(Logo)`
    width: 180px;
    flex-shrink: 0;
    padding-bottom: 6px;

   @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
    width: 220px;
    };
`;
