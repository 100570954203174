import styled from 'styled-components';
import React from 'react';

import { ReactComponent as ArrowIcon } from '../img/arrow.svg';

const Button = ({
    arrow,
    background,
    blank,
    border,
    className,
    color,
    label,
    outline,
    shadow,
    small,
    squared,
    text,
    to,
    width,
    size
}) => {

    return (
        <Root className={ className }
            background={ background }
            color={ color }
            href={ to }
            outline={ outline }
            rel="noreferrer"
            target={ blank ? "_blank" : '' }
            text={ text }
            width={ width }
            border={ border }
            squared={ squared }
            shadow={ shadow }
            small={ small }
            size={ size }
        >
            { label }

            { arrow && <StyledArrowIcon /> }
        </Root>
    );
}

export default Button;

const Root = styled.a`
    align-items: center;
    background: ${ ({ background }) => background ? background : '' };
    border-radius: ${ ({ squared }) => squared ? '0' : '132px' };
    border: 1.5px solid ${ ({ theme, border }) => border ? theme.border : 'transparent' };
    box-shadow: ${ ({ shadow, theme }) => shadow ? `4px 4px 24px ${ theme.overlay }` : '' };
    color: ${ ({ theme, color}) => color ? color : theme.text };
    cursor: pointer;
    display: inline-flex;
    font-family: bold, Arial;
    font-size: ${ ({ size }) => size ? size : '16px' };
    justify-content: center;
    letter-spacing: 0.5px;
    margin: 0; 
    outline: none;
    padding: ${ ({ small }) => small ? '4px 12px' : '20px 32px'};
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    width: ${ ({ width }) => width ? width : ''};

    &:active {
    }

    &:hover {
    }
`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 56px;
    margin-left: 20px;
`;