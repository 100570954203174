import chroma from 'chroma-js';

export const color = {
    grey: {
        grey0: '#EAE9EC',
        grey1: '#D4D3D9',
        grey2: '#BFBDC7',
        grey3: '#AAA7B4',
        grey4: '#9591A1',
        grey5: '#807B8E',
        grey6: '#6C6779',
        grey7: '#585463',
        grey8: '#45424D',
        grey10: '#141414',
    },
    white: '#ffffff',
    gray: '#141414'
}

export const theme = {
    pink: '#F292B8',
    purple: '#a259ff',
    primary: '#0061e1',
    body: color.gray,
    border: chroma(color.white).alpha(0.2),
    text: color.white,
    textSecondary: chroma(color.white).alpha(0.6),
    overlay: chroma(color.gray).alpha(0.4),
    invertTextSecondary: chroma(color.gray).alpha(0.6),
    disabled: chroma(color.white).alpha(0.4),
    placeholder: chroma(color.white).alpha(0.5),
    red: color.red,
    grey1: chroma(color.white).alpha(0.1),
    grey7: chroma(color.white).alpha(0.7),
    grey9: chroma(color.white).alpha(0.9),
    transition: 'all 300ms ease',
    tabletBreakpoint: '1024px'
}
