
import React from 'react';
import styled from 'styled-components';

import Headline01 from '../components/typography/Headline01';
import Headline03 from '../components/typography/Headline03';
import Buttons from '../components/Buttons';
import ListItem from '../components/ListItem';
import video from '../img/teaser.mp4'
import poster from '../img/teaser.jpg'

const FirstPage = () => {
    return (
        <Root>
            <FlexWrapper>
                <Content>
                    <Headline01>
                        <span1>Contactless</span1><br />
                        <span2>future oriented</span2><br />
                        QR menu
                    </Headline01>

                    <Benefits>
                        <ListItem item={ "Save time per table" } />
                        <ListItem item={ "Increase average order value" }/>
                        <ListItem item={ "Decrease operational costs" }/>
                    </Benefits>

                    <Buttons />
                </Content>

                <VideoContent>
                    <Video src={ video } loop poster={ poster } 
                        autoPlay muted playsInline
                    
                    />
                </VideoContent>
            </FlexWrapper>

            <StyledHeadline03>
                Launch digital menu in your restaurant, bar, coffee shop, bakery, food track or hotel <span2>today</span2>
            </StyledHeadline03>
        </Root>
    )}

export default FirstPage;

const Root = styled.div`
    width: 100%;
    padding: 0 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
    }
`;
  
const FlexWrapper = styled.div`
    display: flex;
    height: auto;
    flex-direction: column;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        flex-direction: row; 
        height: 88vh;
    }
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 88px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 50%;
        margin-top: 0;

    }
`;

const VideoContent = styled.div`
    position: relative;
    width: 100%;
    
    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 50%;
        padding-top: 80px;
    }
`;

const Video = styled.video`
    border-radius: 16px;
    position: relative;
    width: 100%;
    object-fit: cover;
    height: 400px;
    object-position: 50% 50%;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        :after {
            content: "";
            display: block;
        }
        height: 100%;
        object-fit: cover;
    }
`;

const StyledHeadline03 = styled(Headline03)`
   margin-top: 24px;

   @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        margin-top: 40px;
    }
`;


const Benefits = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin: 20px 0 24px 0;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        gap: 24px;
        margin: 32px 120px 48px 0;
    }
`;