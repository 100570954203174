import styled from 'styled-components';

const Headline02 = styled.h2`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 7vw;
    font-family: extraBold, Arial;
    letter-spacing: -1px;
    line-height: 120%;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };

    span {
        color: ${ ({ theme }) => theme.primary };
    }
    
    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        font-size: 5vw;
    }
`;

export default Headline02;
