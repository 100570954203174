import styled from 'styled-components';

const Body01 = styled.p`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 22px;
    font-family: ${ ({ bold }) =>
    bold ? 'bold, Arial'
    : 'regular, Arial' };
    letter-spacing: 0;
    line-height: 32px;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };

    span {
        font-family: bold;
    }

    span1 {
        font-family: extraBold;
    }

    span2 {
        color: ${ ({ theme }) => theme.textSecondary };
    }

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        font-size: 24px;
    }
`;

export default Body01;
