import styled from 'styled-components';

const Caption = styled.p`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.textSecondary };
    font-size: 14px;
    font-family: regular;
    letter-spacing: 1.6px;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 12px;
`;

export default Caption;