import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';
import Main from './pages/Main';

const App = () => {
	return (
		<ThemeProvider theme={ theme }>
			<GlobalStyle />

    		<Main />
		</ThemeProvider>
    );
}

export default App;
