import styled, { useTheme } from 'styled-components';
import React from 'react';

import { emailAddress } from '../constants';

import Body03 from '../components/typography/Body03';
import Caption from '../components/typography/Caption';
import Button from '../components/Button';

const Footer = () => {
    const theme = useTheme();

    return (
        <Root> 
            <Caption center >
                   Let's talk 🙂 
            </Caption>

                <Menu>
                    <Button label="WhatsApp" 
                        blank
                        to="https://wa.me/35799881812"
                        small
                    />

                    <Button label="Telegram" 
                        blank
                        to="https://t.me/annazrva"
                        small
                    />

                    <Button label="Email" 
                        blank
                        to={ emailAddress }
                        small
                    />

                    <Button label="Instagram" 
                        blank
                        to="http://instagramm.com/reactionmenu"
                        small
                    />
            
                </Menu>

                <StyledBody03 color={ theme.textSecondary } center>
                    © reaction.menu
                </StyledBody03>
        </Root>
    );
};

export default Footer;

const Root = styled.div`
    width: 100%;
    position: relative;
    padding: 0 16px;
    margin: 80px 0 32px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {

    }
`;


const Menu = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }
`;

const StyledBody03 = styled(Body03)`
    margin-top: 60px;
    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
    }
`;
