import styled, { keyframes } from 'styled-components';

const morph = keyframes`
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; }
  100% {
    border-radius: 40% 60%; }
`;

const spin = keyframes`
    to {
        transform: rotate(360deg); 
    } 
`;

const Shape = ({ color, className }) => {
    return (
        <Root className = { className } 
            color={ color }
        />
    )
}

const Root = styled.div`
    content: "";
	background: ${ ({ theme, color }) => color ? color : theme.primary };
	animation: ${ morph } 8s linear infinite alternate, 
        ${ spin } 24s linear infinite reverse;
	will-change: border-radius, transform;
	pointer-events: none; 
    position: absolute;
    z-index: -1; 
`;

export default Shape;

