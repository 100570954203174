import styled, { useTheme } from 'styled-components';
import React from 'react';

import { signupUrl } from '../constants';
import Body02 from '../components/typography/Body02';
import Caption from '../components/typography/Caption';
import Headline01 from '../components/typography/Headline01';
import Headline02 from '../components/typography/Headline02';
import Headline03 from '../components/typography/Headline03';
import ListItem from '../components/ListItem';

const Footer = () => {
    const theme = useTheme();

    return (
        <Root> 
            <Caption center >
                   How much does it cost? 🤓
            </Caption>

               <StyledHeadline03 center >
                   Take your business<br />to the next level
               </StyledHeadline03>

               <Headline01 center regular >
                   €29/mo
               </Headline01>

               <StyledBody02 center color={ theme.textSecondary }>
                    Get 2 months free when billed<br />annually - <span>€290/year</span>
               </StyledBody02>

               <Benefits>
                    <ListItem item={ "Unlimited changes" } />
                    <ListItem item={ "Unlimited items" } />
                    <ListItem item={ "Unlimited images" } />
                </Benefits>


                <Link href={ signupUrl }
                    target="_blank"
                >
                    <StyledHeadline02 center>Create subscription</StyledHeadline02>
                </Link>

        </Root>
    );
};

export default Footer;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
    }
`;

const StyledHeadline03 = styled(Headline03)`
    margin: 0 0 16px;
`;

const StyledBody02 = styled(Body02)`
    margin-top: 16px;
`;

const Benefits = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 32px 0 24px;
    justify-content: center;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        gap: 24px;
        flex-direction: row;
    }
`;

const StyledHeadline02 = styled(Headline02)`
    background: ${ ({ theme }) => theme.primary };
    padding: 30px 0px;
    border-radius: 132px;
    white-space: nowrap;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 40px 80px;
    }
`;

const Link = styled.a`
    width: 100%;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: auto;
    }
`;
